import React, { useContext, useState } from "react";
import CommerceLayer from "@commercelayer/sdk/lib/cjs/commercelayer";
import ArrowDown from "../assets/img/arrow_down.svg";
import ArrowUp from "../assets/img/arrow_up.svg";

import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Button,
  Image,
  Grid,
} from "theme-ui";
import CustomerTokenContext from "../hooks/customerTokenContext";
import { navigate } from "gatsby";
import { InboundLink, OutboundLink } from "../components/link";
import Logo from "../assets/img/logo.svg";
import LogoWhite from "../assets/img/logo-white.svg";
import CustomInput from "../components/customInput";
import EmailIcon from "../assets/img/icons/email.inline.svg";
import PasswordIcon from "../assets/img/icons/password.inline.svg";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import SignUp from "../assets/img/sign_up.svg";

const LoginPage = () => {
  const [data, setData] = useState({ username: "", password: "" });
  const [loginError, setLoginError] = useState(null);
  const { customerToken, setCustomerToken } = useContext(CustomerTokenContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const handleFormSubmit = async (e) => {
    try {
      const response = await fetch('https://auth.commercelayer.io/oauth/token', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          grant_type: 'password',
          username: e.email,
          password: e.password,
          client_id: 'nr6l5bKbn2tV-iMgkgS0z4KTsvrv_5eu6Dfm5E2fATE',
          scope: 'market:11461'
        })
      });

      const clToken = await response.json();

      if (clToken.access_token) {
        const cl = CommerceLayer({
          organization: "socaf-s-p-a",
          accessToken: clToken.access_token,
        });
        
        const getCostumer = async () => {
          console.log("enters");
          const handleError = (e) => {
            if (e.errors[0].code === "INVALID_TOKEN") {
              setCustomerToken(null);
              navigate("/login");
            }
          };

          const customer = await cl.customers
            .retrieve(clToken.owner_id)
            .catch(handleError);

          if (
            customer.metadata.enabled === false ||
            customer.metadata.enabled === "false"
          ) {
            setLoginError("user");
          } else {
            setCustomerToken(clToken);
            navigate("/");
            setLoginError("");
          }
        };

        getCostumer();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (e) => {
    setLoginError("error");
  };

  const handleChange = (e) => {
    const name = e.target.name === "email" ? "username" : e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  return (
    <>
      <Helmet>
        <title>Login | Socaf</title>
      </Helmet>
      {/*  <OutsideNav /> */}
      <Container
        sx={{
          p: [0, 0, 0, 0],
        }}
        variant="fw"
      >
        <Grid
          columns={[1, 1, 1, "3fr 2fr"]}
          sx={{ minHeight: ["unset", "unset", "100vh", "100vh"] }}
          gap={[0]}
        >
          <Flex
            sx={{
              minHeight: ["100vh", "100vh", "unset", "unset"],
              position: "relative",
              flexDirection: "column",
              justifyContent: "space-between!important",
              alignItems: "center",
              padding: [0, 2, 2, 5],
              gap: 2,
              backgroundColor: "secondary",
            }}
          >
            <Box
              sx={{
                mx: 4,

                my: 2,
                display: ["block", "block", "none", "none"],
                width: "80%",
                alignSelf: "start",
              }}
            >
              <Box>
                <InboundLink to="/">
                  <Image
                    src={LogoWhite}
                    sx={{
                      mt: 5,
                      maxHeight: "50px",
                      minHeight: "50px",
                    }}
                  />
                </InboundLink>
              </Box>
            </Box>
            <Box
              sx={{
                margin: "auto",
                width: ["90%", "100%", "100%", "70%"],
                height: ["100%", "100%"],

                p: [2, 2, 2, 5],
              }}
            >
              <Image sx={{ width: "100%", height: "100%" }} src={SignUp} />
            </Box>

            <Flex
              sx={{
                flexDirection: "column",
                justifyContent: "center",

                padding: [6, 7, 7, 5],
                border: "1px solid white",
                margin: [0, 0, 0, "auto"],
                textAlign: "center",
                fontSize: [8, 8, 8, "34px"],
                color: "white",
                width: ["90%", "70%"],
              }}
            >
              <Box sx={{ fontWeight: "bold" }}>sconto del 35%</Box>
              <Box> sul primo ordine</Box>
              <Box sx={{ fontSize: [2, 3, 3, "50px"] }}> + </Box>
              <Box> consegna gratuita</Box>
              <Box> sopra i 200 €</Box>
              <OutboundLink
                sx={{ mt: 4 }}
                href={
                  "https://us20.list-manage.com/survey?u=5fd38aee20b7fcb3f04dfcdea&id=280458ac7d&attribution=false"
                }
              >
                <Button
                  variant="buttons.secondary"
                  type="submit"
                  value="Accedi"
                  sx={{
                    backgroundColor: "light",
                    color: "secondary",
                    cursor: "pointer",
                    opacity: 1,
                    width: "60%",
                    height: "100%",
                    textAlign: "center",
                    fontSize: ["15px", "15px"],
                    whiteSpace: "nowrap",
                    fontWeight: "600",
                    borderRadius: "unset",
                    p: [3],
                    "&:hover": {
                      backgroundColor: "dark",
                    },
                  }}
                >
                  Registrati ora
                </Button>
              </OutboundLink>
              <Text sx={{ fontSize: [3, 3, 3, "12px"], mt: 3 }}>
                Dopo la registrazione, verrai contattato al più presto da un
                nostro referente per ricevere le credenziali di accesso.
              </Text>
            </Flex>
            <Box
              sx={{
                display: ["block", "block", "none", "none"],
                width: "100%",
              }}
            >
              <RedBanner />
            </Box>
          </Flex>

          <Container
            sx={{
              mt: 5,
              display: "flex",
              gap: [4, 4, 0, 2],
              flexDirection: "column",
              justifyContent: [
                "flex-start",
                "flex-start",
                "flex-start",
                "space-between",
              ],
              height: ["100vh", "100vh", "unset", "unset"],
            }}
          >
            <Box
              sx={{
                display: ["none", "none", "block", "block"],
                mt: [5, 4, 0, 0],
                margin: [0, 0, 0, "auto"],
                width: ["100%", "80%"],
              }}
            >
              <Box>
                <InboundLink to="/">
                  <Image
                    src={Logo}
                    sx={{
                      height: ["30px", "80px"],
                      maxHeight: "80px",
                      minHeight: "80px",
                    }}
                  />
                </InboundLink>
              </Box>
            </Box>

            <Box
              sx={{
                margin: [0, 0, 0, "auto"],
                width: ["100%", "100%", "100%", "80%"],
              }}
            >
              <Heading
                as="h1"
                variant="h2"
                sx={{
                  color: "primary",
                  fontSize: ["40px", "40px"],
                  mb: [9],
                }}
              >
                Accedi
              </Heading>
              <Box
                sx={{ width: "100% " }}
                as="form"
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <Box sx={{ pb: [4] }}>
                  <CustomInput
                    id="email"
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    variant="inputs.dark"
                    autocomplete="email"
                    register={register}
                    errors={errors}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => handleChange(e),
                    }}
                    icon={true}
                  >
                    <Flex
                      sx={{
                        minWidth: "26px",
                        width: "fit-content!important",
                        position: "absolute",
                        left: [2],
                        top: "50%",
                        justifyContent: "center",
                        justifyItems: "center",
                        transform: "translateY(-50%)",
                        svg: {
                          width: "24px",
                        },
                      }}
                    >
                      <EmailIcon />
                    </Flex>
                  </CustomInput>
                </Box>
                <Box sx={{ pb: [4] }}>
                  <CustomInput
                    id="password"
                    label="Password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    autocomplete="password"
                    variant="inputs.dark"
                    icon={true}
                    forgotPassword={true}
                    register={register}
                    errors={errors}
                    validationSchema={{
                      required: "Questo campo è obbligatorio.",
                      onChange: (e) => handleChange(e),
                    }}
                  >
                    <Flex
                      sx={{
                        minWidth: "26px",
                        width: "fit-content!important",
                        position: "absolute",
                        left: [2],
                        top: "50%",
                        justifyContent: "center",
                        justifyItems: "center",
                        transform: "translateY(-50%)",
                        svg: {
                          width: "24px",
                        },
                      }}
                    >
                      <PasswordIcon />
                    </Flex>
                  </CustomInput>
                </Box>
                {loginError && (
                  <Box sx={{ pb: 4 }}>
                    <Text sx={{ color: "primary" }}>
                      {loginError === "error"
                        ? "L'email o la password che hai inserito non sono corretti"
                        : "Questo account non è autorizzato ad accedere al portale, contattare l'assistenza allo 0354876054"}
                    </Text>
                  </Box>
                )}

                <Button
                  variant="buttons.primary"
                  type="submit"
                  value="Accedi"
                  sx={{
                    opacity: 1,
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    fontSize: [5, 5, 5, 3],
                    fontWeight: "600",
                    borderRadius: "unset",
                    p: [3],
                  }}
                >
                  Accedi
                </Button>
                <Flex
                  sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    color: "primary",
                    py: 6,
                    gap: 3,
                  }}
                >
                  <Box sx={{ textAlign: "center" }}>
                    <InboundLink
                      sx={{ fontSize: [4, 2, 2, 1] }}
                      to={"/forgot-password"}
                    >
                      Password dimenticata?
                    </InboundLink>
                  </Box>
                </Flex>
                <Box
                  sx={{
                    textAlign: "center",
                    color: "lightBorder",
                    fontSize: [4, 2],
                  }}
                >
                  Non riesci ad accedere al servizio? <br /> Contattaci allo
                  0354876054
                </Box>
              </Box>
            </Box>
          </Container>
          <Box
            sx={{
              display: ["block", "block", "none", "none"],
            }}
          >
            <BlueBanner />
          </Box>
        </Grid>
      </Container>
    </>
  );
};

const RedBanner = () => {
  const handleClick = () => {
    window.scrollBy({ top: window.innerHeight, behavior: "smooth" });
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        backgroundColor: "primary",
        color: "light",
        width: "100%",

        py: 6,
        height: "30px",
        textAlign: "center",
        display: ["flex", "flex", "none", "none"],
        justifyContent: "center",

        alignItems: "center",
        fontSize: ["21px"],
      }}
    >
      <Text sx={{}}>Hai già le credenziali ? Accedi</Text>
      <Image sx={{ ml: 4 }} src={ArrowUp} />
    </Box>
  );
};
const BlueBanner = () => {
  const handleClick = () => {
    window.scrollBy({ top: -window.innerHeight, behavior: "smooth" });
  };
  return (
    <Box
      onClick={handleClick}
      sx={{
        backgroundColor: "secondary",
        color: "light",
        width: "100%",

        py: 6,
        height: "20px",
        textAlign: "center",
        display: ["flex", "flex", "none", "none"],
        justifyContent: "center",
        alignItems: "center",
        fontSize: ["21px"],
      }}
    >
      <Text sx={{}}>Non hai le credenziali? Registrati</Text>
      <Image sx={{ ml: 4 }} src={ArrowDown} />
    </Box>
  );
};

export default LoginPage;
